<template>
  <BlogListComponent />
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import BlogListComponent from "@/components/blog/blogList/BlogListComponent.vue";

@Component({
  components: {
    BlogListComponent,
  },
})
export default class BlogsView extends Vue {}
</script>
