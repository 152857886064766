import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VParallax } from 'vuetify/lib/components/VParallax';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(VContainer,[_c(VRow,{staticClass:"py-2",attrs:{"justify":"center","align":"center"}},[_c(VCol,{attrs:{"cols":"8","lg":"6","md":"6","sm":"4"}},[_c('router-link',{attrs:{"to":"/"}},[_c(VImg,{attrs:{"contain":"","height":"50","src":require('@/assets/logo.png')}})],1)],1),_c(VCol,{attrs:{"cols":"8","lg":"6","md":"6","sm":"4"}},[_c('div',{staticClass:"d-flex align-center flex-column font-weight-light"},[_c('a',{attrs:{"href":'tel:' + _vm.$t('phoneNumber1')}},[_c('span',[_vm._v(_vm._s(_vm.$t("phonePrefix") + " " + _vm.$t("phoneNumber1")))])]),_c('a',{attrs:{"href":'tel:' + _vm.$t('phoneNumber2')}},[_c('span',[_vm._v(_vm._s(_vm.$t("phonePrefix") + " " + _vm.$t("phoneNumber2")))])]),_c('a',{attrs:{"href":'mailto:' + _vm.$t('email')}},[_c('span',[_vm._v(_vm._s(_vm.$t("emailPrefix") + " " + _vm.$t("email")))])])])])],1)],1),_c(VParallax,{attrs:{"src":require('@/assets/header_blog.avif'),"height":"300"}},[_c('div',{staticClass:"parallax-overlay"}),_c(VRow,{attrs:{"align":"center","justify":"start"}},[_c(VCol,{staticClass:"text-h4 font-italic overlay-text text-wrap text-center",attrs:{"cols":"10","lg":"10","md":"10","sm":"10"}},[_vm._v(" "+_vm._s(_vm.$t("blogHeaderTitle"))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }