<template>
  <v-navigation-drawer
    app
    expand-on-hover
    :mini-variant.sync="mini"
    class="backgroundDark"
  >
    <v-list>
      <v-list-item
        v-for="(item, index) in menu"
        :key="index"
        @click="handleItemClick(item.id)"
      >
        <v-list-item-icon>
          <v-icon class="secondary--text">{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title class="secondary--text">
            {{ $t(item.titleKey) }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
@Component({})
export default class NavigationDrawerComponent extends Vue {
  mini = true;
  @Prop({ default: [] }) menu!: {
    titleKey: string;
    icon: string;
    id: string;
  }[];

  @Emit("item-click")
  handleItemClick(itemId: string) {
    return itemId;
  }
}
</script>
