import { ActionTree } from 'vuex';

import { BlogCategory, BlogResponse } from '@/components/blog/shared/types';
import { getBlogCategories, getBlogs } from '@/firebase';
import { RootState } from '@/store/RootState';

import { BlogState } from './types';

export const actions: ActionTree<BlogState, RootState> = {
  async fetchBlogs({ commit }): Promise<void> {
    try {
      const response: Array<BlogResponse> = await getBlogs();
      commit("setBlogs", response);
    } catch (error) {
      commit("setError");
    }
  },

  async fetchBlogCategories({ commit }): Promise<void> {
    try {
      const response: Array<BlogCategory> = await getBlogCategories();
      commit("setCategories", response);
    } catch (error) {
      commit("setError");
    }
  },
};
