import { Module } from 'vuex';

import { RootState } from '@/store/RootState';

import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { BlogDetailsState } from './types';

export const blogDetails: Module<BlogDetailsState, RootState> = {
  namespaced: true,
  state: {
    category: undefined,
    blog: undefined,
  },
  getters: getters,
  mutations: mutations,
  actions: actions,
};
