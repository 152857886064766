import { MutationTree } from 'vuex';

import { BlogCategory, BlogResponse } from '@/components/blog/shared/types';

import { BlogDetailsState } from './types';

export const mutations: MutationTree<BlogDetailsState> = {
  setBlog(state: BlogDetailsState, blog: BlogResponse): void {
    state.blog = blog;
  },

  setCategory(state: BlogDetailsState, category: BlogCategory): void {
    state.category = category;
  },
};
