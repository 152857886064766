import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VFooter,{staticClass:"background",attrs:{"padless":""}},[_c(VImg,{attrs:{"height":"4rem","contain":"","src":require("@/assets/img/borderWavesGreen.svg")}}),_c(VCard,{staticClass:"primary white--text text-center",attrs:{"flat":"","tile":""}},[_c(VCardText,_vm._l((_vm.icons),function(icon,i){return _c(VBtn,{key:i,staticClass:"mx-4 white--text",attrs:{"href":icon.link,"target":"_blank","icon":""}},[_c(VIcon,{attrs:{"size":"24px"}},[_vm._v(_vm._s(icon.text))])],1)}),1),_c(VDivider),_c(VCardText,{staticClass:"white--text"},[_vm._v(" "+_vm._s(new Date().getFullYear())+" — "+_vm._s(_vm.$t("copyright"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }