import { ActionTree } from 'vuex';

import { BlogCategory, BlogResponse } from '@/components/blog/shared/types';
import { getBlogById, getCategoryById } from '@/firebase';
import { RootState } from '@/store/RootState';

import { BlogDetailsState } from './types';

export const actions: ActionTree<BlogDetailsState, RootState> = {
  async fetchBlog({ commit }, id: string): Promise<void> {
    try {
      const response: BlogResponse | null = await getBlogById(id);
      if (response) {
        commit("setBlog", response);
      }
    } catch (error) {
      // TODO: Hanlde error
    }
  },

  async fetchCategory({ commit }, id: string): Promise<void> {
    try {
      const response: BlogCategory | null = await getCategoryById(id);
      if (response) {
        commit("setCategory", response);
      }
    } catch (error) {
      // TODO: Hanlde error
    }
  },
};
