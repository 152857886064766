<template>
  <v-container fluid fill-height>
    <v-row justify="center" align-self="center">
      <v-col cols="12" sm="6" md="4">
        <v-progress-circular indeterminate color="brand"></v-progress-circular>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class LoadingIndicatorComponent extends Vue {}
</script>
