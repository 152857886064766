import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';

import { blogDetails } from '@/components/blog/blogDetails/store';
import { blog } from '@/components/blog/blogList/store';

import { RootState } from './RootState';

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  state: { version: "0.0.1", name: "Invictus" },
  modules: { blog, blogDetails },
};

export default new Vuex.Store<RootState>(store);
