<template>
  <div>
    <BlogHeaderComponent />

    <LoadingIndicatorComponent v-if="loading" />

    <v-container max-width="1000" fill-height class="background" v-else>
      <!-- Title -->
      <v-row justify="center" class="mt-4">
        <p class="text-h4 font-weight-bold text-center">
          {{ blog?.title }}
        </p>
      </v-row>

      <!-- Image -->
      <v-img contain :src="blog?.imageURL" max-height="500" class="mt-4" />

      <!-- Description -->
      <p class="text-h6 mt-12" v-html="blog?.description" />

      <v-row justify="start" class="mt-12" v-if="blog">
        <BlogShareComponent :pageUrl="getPageUrl()" />
      </v-row>

      <v-row justify="space-between" class="mt-4 mx-2">
        <!-- Category -->
        <p class="text-h5 font-weight-bold" v-if="category">
          {{ $t("blogCategory") + category?.name }}
        </p>

        <!-- Created at -->
        <p class="text-overline" v-if="blog">
          {{
            $t("blogCreatedAt") + " " + formattedCreatedAtDate(blog?.createdAt)
          }}
        </p>
      </v-row>

      <v-row v-if="blog">
        <v-divider class="secondary darken-4"></v-divider>
      </v-row>
      <v-row class="mt-8 mb-8" justify="space-between" v-if="blog">
        <TextButtonComponent
          :title="$t('navigateBack')"
          :action="popToBlogs"
          leftIcon="mdi-arrow-left-drop-circle"
          buttonClass="text-h6"
        />

        <TextButtonComponent
          :title="$t('scrollToTop')"
          :action="scrollToTop"
          leftIcon="mdi-arrow-up-drop-circle"
          buttonClass="text-h6"
        />
      </v-row>

      <v-container fluid v-if="!blog">
        <v-row justify="center" class="mb-2">
          <v-icon size="80" color="error">mdi-alert</v-icon>
        </v-row>
        <p class="text-h5 font-weight-bold text-center">
          {{ $t("blogFetchingError") }}
        </p>
      </v-container>
    </v-container>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { BlogCategory, BlogResponse } from "@/components/blog/shared/types";
import { Action, Getter } from "vuex-class";
import TextButtonComponent from "@/components/common/TextButtonComponent.vue";
import LoadingIndicatorComponent from "@/components/common/LoadingIndicatorComponent.vue";
import BlogHeaderComponent from "@/components/blog/BlogHeaderComponent.vue";
import BlogShareComponent from "@/components/blog/blogDetails/BlogShareComponent.vue";

@Component({
  components: {
    TextButtonComponent,
    LoadingIndicatorComponent,
    BlogHeaderComponent,
    BlogShareComponent,
  },
})
export default class BlogDetailsComponent extends Vue {
  loading = true;

  @Action("blogDetails/fetchBlog")
  fetchBlog!: (id: string) => Promise<void>;
  @Action("blogDetails/fetchCategory")
  fetchCategory!: (id: string) => Promise<void>;

  @Getter("blogDetails/blog")
  readonly blog?: BlogResponse | null;
  @Getter("blogDetails/category")
  readonly category?: BlogCategory | null;

  formattedCreatedAtDate(isoString?: string): string {
    if (!isoString) {
      return "unknown";
    }
    var date = new Date(isoString);
    return date.toLocaleDateString("de");
  }

  getPageUrl(): string {
    return window.location.href;
  }

  popToBlogs(): void {
    this.$router.go(-1);
  }

  scrollToTop(): void {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  mounted(): void {
    if (!this.blog) {
      const blogId = this.$route.params.id;
      this.fetchBlog(blogId)
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.popToBlogs();
        });
    } else {
      this.loading = false;
    }
  }
}
</script>
