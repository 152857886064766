import { GetterTree } from 'vuex';

import { BlogCategory, BlogResponse } from '@/components/blog/shared/types';
import { RootState } from '@/store/RootState';

import { BlogDetailsState } from './types';

export const getters: GetterTree<BlogDetailsState, RootState> = {
  blog(state: BlogDetailsState): BlogResponse | null {
    return state.blog ?? null;
  },

  category(state: BlogDetailsState): BlogCategory | null {
    return state.category ?? null;
  },
};
