import { MutationTree } from 'vuex';

import { BlogCategory, BlogResponse } from '@/components/blog/shared/types';

import { BlogState } from './types';

export const mutations: MutationTree<BlogState> = {
  setBlogs(state: BlogState, payload: [BlogResponse]): void {
    state.blogs = payload;
  },

  setCategories(state: BlogState, payload: [BlogCategory]): void {
    state.categories = payload;
  },

  setError(state: BlogState): void {
    state.error = true;
  },
};
