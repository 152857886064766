<template>
  <div data-aos="fade-right" data-aos-delay="300" data-aos-duration="600">
    <v-container class="mt-8 primary px-3" fluid>
      <!-- Clients -->
      <v-row justify="center">
        <v-col cols="11" md="10" lg="10" sm="11">
          <v-card class="elevation-0 mt-12">
            <Swiper class="swiper" :options="swiperOption">
              <SwiperSlide v-for="(item, index) in carouselItems" :key="index">
                <v-card
                  height="70"
                  class="d-flex align-center flex-column pa-4 elevation-0"
                >
                  <v-img
                    class="image-wrapper"
                    :src="require(`@/assets/clients/${item}`)"
                    height="70"
                    contain
                  />
                </v-card>
              </SwiperSlide>
            </Swiper>
          </v-card>
        </v-col>
        <!-- Locations -->
        <v-col cols="11" md="10" lg="10" sm="11">
          <v-list class="mt-2 pa-0">
            <v-card outlined class="elevation-0">
              <v-list-group>
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title
                      class="text-h6 font-weight-normal text-uppercase"
                    >
                      {{ $t("locations") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item
                  v-for="(location, index) in locations"
                  :key="index"
                  class="mx-4"
                >
                  <v-list-item-content>
                    <v-list-item-title class="mb-3">
                      {{ location }}
                    </v-list-item-title>
                    <v-divider v-if="index != locations.length - 1"></v-divider>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
            </v-card>
          </v-list>
        </v-col>
      </v-row>

      <!-- Reviews -->
      <v-row justify="center" align="center" class="mt-6">
        <span class="text-uppercase display-2 text-center pa-4 white--text">
          {{ $t("reviewTitle") }}
        </span>
      </v-row>
      <v-row justify="center" class="my-8">
        <v-col cols="11" md="10" lg="10" sm="11">
          <v-card class="elevation-0 transparent">
            <Swiper class="swiper" :options="reviewSwiperOption">
              <SwiperSlide v-for="(review, index) in reviews" :key="index">
                <v-card class="pt-8 pb-4 elevation-0" outlined>
                  <v-row justify="center">
                    <v-icon size="90">{{ review.icon }}</v-icon>
                  </v-row>
                  <v-card
                    height="250"
                    class="font-italic text-start elevation-0 mt-8 pa-0"
                  >
                    <v-sheet
                      class="overflow-y-auto custom-scrollbar px-4"
                      height="100%"
                    >
                      {{ $t(review.titleKey) }}
                    </v-sheet>
                  </v-card>

                  <div class="px-4 mt-3">
                    <div class="font-weight-bold primary--text">
                      {{ $t(review.writerKey) }}
                    </div>
                    <div class="font-italic primary--text">
                      {{ $t(review.companyKey) }}
                    </div>
                  </div>
                </v-card>
              </SwiperSlide>
            </Swiper>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import "aos/dist/aos.css";

@Component({ components: { Swiper, SwiperSlide } })
export default class ClientsComponent extends Vue {
  // More about swiper API -> https://swiperjs.com/swiper-api
  swiperOption = {
    slidesPerView: 6,
    spaceBetween: 2,
    loop: false,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    speed: 800,
    grabCursor: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      type: "bullets",
    },
    breakpoints: {
      1024: {
        slidesPerView: 8,
      },
      768: {
        slidesPerView: 4,
      },
      640: {
        slidesPerView: 2,
      },
      320: {
        slidesPerView: 2,
      },
    },
  };

  reviewSwiperOption = {
    slidesPerView: 3,
    spaceBetween: 10,
    loop: false,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    speed: 800,
    grabCursor: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      type: "bullets",
    },
    breakpoints: {
      1024: {
        slidesPerView: 3,
      },
      768: {
        slidesPerView: 3,
      },
      640: {
        slidesPerView: 2,
      },
      320: {
        slidesPerView: 1,
      },
    },
  };

  carouselItems = [
    "client7.svg",
    "client8.svg",
    "client9.svg",
    "client10.svg",
    "client11.svg",
    "client12.svg",
    "client13.svg",
    "client14.svg",
    "client15.svg",
    "client16.svg",
    "client17.svg",
    "client18.svg",
    "client (1).png",
    "client (2).png",
    "client (3).png",
    "client (4).png",
    "client (5).png",
    "client (6).png",
    "client (7).png",
    "client (8).png",
    "client (9).png",
    "client (10).png",
    "client (11).png",
    "client (12).png",
    "client (13).png",
    "client (14).png",
    "client (15).png",
    "client (16).png",
    "client (17).png",
    "client (18).png",
    "client (19).png",
    "client (20).png",
    "client (21).png",
    "client (22).png",
    "client (23).png",
  ];

  locations = ["Sarajevo", "Mostar", "Zenica", "Goražde", "Bugojno"];

  reviews = [
    {
      icon: "mdi-comment-check-outline",
      titleKey: "review1",
      writerKey: "reviewClient1",
      companyKey: "reviewCompany1",
    },
    {
      icon: "mdi-comment-check-outline",
      titleKey: "review2",
      writerKey: "reviewClient2",
      companyKey: "reviewCompany2",
    },
    {
      icon: "mdi-comment-check-outline",
      titleKey: "review3",
      writerKey: "reviewClient3",
      companyKey: "reviewCompany3",
    },
    {
      icon: "mdi-comment-check-outline",
      titleKey: "review1",
      writerKey: "reviewClient1",
      companyKey: "reviewCompany1",
    },
    {
      icon: "mdi-comment-check-outline",
      titleKey: "review2",
      writerKey: "reviewClient2",
      companyKey: "reviewCompany2",
    },
    {
      icon: "mdi-comment-check-outline",
      titleKey: "review3",
      writerKey: "reviewClient3",
      companyKey: "reviewCompany3",
    },
  ];
}
</script>

<style scoped>
.image-wrapper {
  opacity: 0.5;
  filter: grayscale(100%);
}

.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

.background-view {
  background-color: var(--v-primary-base);
  position: absolute;
  height: 800px;
  left: 0;
  z-index: -1;
}
</style>
