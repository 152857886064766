<template>
  <v-card align="center">
    <v-speed-dial
      v-model="dialShare"
      absolute
      left
      bottom
      direction="right"
      open-on-hover
    >
      <template v-slot:activator>
        <v-btn fab bottom small color="primary darken-3">
          <v-icon v-if="dialShare">mdi-close</v-icon>
          <v-icon v-else>mdi-share-variant</v-icon>
        </v-btn>
      </template>
      <v-btn
        dark
        fab
        bottom
        color="blue darken-7"
        small
        :href="`https://www.linkedin.com/shareArticle?mini=true&url=${pageUrl}`"
        target="_blank"
      >
        <v-icon>mdi-linkedin</v-icon>
      </v-btn>
      <v-btn
        dark
        fab
        bottom
        color="blue"
        small
        :href="`https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`"
        target="_blank"
      >
        <v-icon>mdi-facebook</v-icon>
      </v-btn>
      <v-btn
        dark
        fab
        bottom
        color="green"
        small
        :href="`https://wa.me/?text=Checkout%20this%20page:%20${pageUrl}`"
        target="_blank"
      >
        <v-icon>mdi-whatsapp</v-icon>
      </v-btn>
      <v-btn
        dark
        fab
        bottom
        small
        :href="`mailto:?subject=Invictus&amp;body=Checkout this page!<a href='${pageUrl}'>${pageUrl}</a>`"
        target="_blank"
      >
        <v-icon>mdi-email</v-icon>
      </v-btn>

      <v-btn
        dark
        fab
        bottom
        color="tertiary"
        small
        v-clipboard:copy="pageUrl"
        v-clipboard:success="onCopy"
      >
        <v-icon>mdi-link-variant</v-icon>
      </v-btn>
    </v-speed-dial>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ $t("blogLinkCopied") }}
      <template v-slot:action="{ attrs }">
        <v-btn color="success" text v-bind="attrs" @click="snackbar = false">
          {{ $t("formSystemMessageClose") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import VueClipboard from "vue-clipboard2";

Vue.use(VueClipboard);

@Component
export default class BlogShareComponent extends Vue {
  @Prop({ type: String, required: true }) pageUrl!: string;

  dialShare = false;
  snackbar = false;
  timeout = 1000;

  onCopy(): void {
    this.snackbar = true;
  }
}
</script>
