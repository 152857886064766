import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VNavigationDrawer,{staticClass:"backgroundDark",attrs:{"app":"","expand-on-hover":"","mini-variant":_vm.mini},on:{"update:miniVariant":function($event){_vm.mini=$event},"update:mini-variant":function($event){_vm.mini=$event}}},[_c(VList,_vm._l((_vm.menu),function(item,index){return _c(VListItem,{key:index,on:{"click":function($event){return _vm.handleItemClick(item.id)}}},[_c(VListItemIcon,[_c(VIcon,{staticClass:"secondary--text"},[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"secondary--text"},[_vm._v(" "+_vm._s(_vm.$t(item.titleKey))+" ")])],1)],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }