import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import BlogDetailsView from '@/views/BlogDetailsView.vue';
import BlogsView from '@/views/BlogsView.vue';
import HomeView from '@/views/HomeView.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/articles",
    name: "blog",
    component: BlogsView,
  },
  {
    path: "/article/:id",
    name: "blogDetails",
    component: BlogDetailsView,
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});
export default router;
