<template>
  <BlogDetailsComponent />
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import BlogDetailsComponent from "@/components/blog/blogDetails/BlogDetailsComponent.vue";

@Component({
  components: {
    BlogDetailsComponent,
  },
})
export default class BlogDetailsView extends Vue {}
</script>
