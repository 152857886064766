<template>
  <div>
    <v-chip
      v-for="(item, index) in items"
      :key="index"
      :value="getItemValue(item)"
      :color="chipColor"
      :class="chipClass"
      filter
    >
      {{ getItemText(item) }}
    </v-chip>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class GenericChipComponent extends Vue {
  @Prop({ type: Array, required: true }) items!: (
    | string
    | { id: string; name: string }
  )[];
  @Prop({ type: String, default: " white primary--text" })
  chipColor!: string;
  @Prop({ type: String, default: "mr-2" }) chipClass!: string;

  getItemValue(item: string | { id: string; name: string }): string {
    return typeof item === "string" ? item : item.id;
  }

  getItemText(item: string | { id: string; name: string }): string {
    return typeof item === "string" ? `#${item}` : item.name;
  }
}
</script>
