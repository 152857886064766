<template>
  <v-footer class="background" padless>
    <v-img height="4rem" contain src="@/assets/img/borderWavesGreen.svg" />
    <v-card flat tile class="primary white--text text-center">
      <v-card-text>
        <v-btn
          v-for="(icon, i) in icons"
          :key="i"
          class="mx-4 white--text"
          :href="icon.link"
          target="_blank"
          icon
        >
          <v-icon size="24px">{{ icon.text }}</v-icon>
        </v-btn>
      </v-card-text>

      <v-divider />

      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — {{ $t("copyright") }}
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<style scoped>
.v-card {
  width: 100%;
}
</style>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class FooterComponent extends Vue {
  icons = [
    {
      text: "mdi-facebook",
      link: "https://www.facebook.com/realinvictus.ba",
    },
    {
      text: "mdi-instagram",
      link: "https://instagram.com/",
    },
  ];
}
</script>
