<template>
  <LoadingIndicatorComponent v-if="loading" />
  <div v-else>
    <BlogHeaderComponent />
    <v-container>
      <v-container fluid class="mb-8 px-4 py-0">
        <v-row class="mt-8" cols="12" md="6" lg="6">
          <!-- Back button -->
          <v-btn
            icon
            color="primary darken-1"
            class="mt-1 mr-1"
            @click="popToBlogs"
          >
            <v-icon size="32">mdi-arrow-left-drop-circle</v-icon>
          </v-btn>

          <!-- Search text field -->
          <v-text-field
            v-model="searchText"
            :label="$t('blogSearch')"
            outlined
            dense
            color="primary darken-1"
            append-icon="mdi-magnify"
            hide-details
          />
        </v-row>

        <!-- Category filter -->
        <v-row justify="start" class="mt-4">
          <v-chip-group column v-model="selectedCategoryId">
            <v-chip
              v-for="category in categories"
              :key="category.id"
              :value="category.id"
              color="primary darken-1 secondary--text"
              filter
            >
              {{ category.name }}
            </v-chip>
          </v-chip-group>
        </v-row>
      </v-container>

      <v-list class="px-2 background">
        <v-list-item v-for="blog in filteredBlogs" :key="blog.id">
          <v-list-item-content
            data-aos="fade"
            data-aos-delay="150"
            data-aos-duration="300"
          >
            <v-row justify="space-around">
              <v-col cols="12" lg="8" md="12">
                <h4 class="text-h4" v-html="blog.title" />
                <p
                  class="text-subtitle-1 pt-4"
                  v-html="
                    blog.description.substring(0, maxNumberOfCharacters) + '...'
                  "
                />
                <v-btn
                  color="primary darken-1 secondary--text"
                  @click="readMore(blog)"
                  class="mt-4"
                >
                  {{ $t("readMore") }}
                </v-btn>
              </v-col>
              <v-col cols="12" lg="4" md="12">
                <v-img
                  cover
                  height="300"
                  aspect-ratio="4/3"
                  :src="blog.imageURL"
                />
              </v-col>
            </v-row>
            <v-divider class="mt-3" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-container>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { BlogCategory, BlogResponse } from "@/components/blog/shared/types";
import LoadingIndicatorComponent from "@/components/common/LoadingIndicatorComponent.vue";
import TextButtonComponent from "@/components/common/TextButtonComponent.vue";
import BlogHeaderComponent from "../BlogHeaderComponent.vue";

@Component({
  components: {
    LoadingIndicatorComponent,
    TextButtonComponent,
    BlogHeaderComponent,
  },
})
export default class BlogListComponent extends Vue {
  searchText = "";
  selectedCategoryId = null;
  loading = true;
  maxNumberOfCharacters = 450;
  filteredBlogs: BlogResponse[] = [];

  @Getter("blog/blogs")
  readonly blogs!: BlogResponse[];
  @Getter("blog/categories")
  readonly categories!: BlogCategory[];

  @Mutation("blogDetails/setBlog")
  setActiveBlog!: (blog: BlogResponse) => void;
  @Mutation("blogDetails/setCategory")
  setActiveCategory!: (category: BlogCategory) => void;

  @Action("blog/fetchBlogs")
  fetchBlogs!: () => Promise<void>;
  @Action("blog/fetchBlogCategories")
  fetchBlogCategories!: () => Promise<void>;

  @Watch("selectedCategoryId")
  onCategoryChange(newValue: string) {
    this.filteredBlogs = this.blogs;
    if (newValue != undefined || newValue != null) {
      this.filteredBlogs = this.filteredBlogs.filter((element) => {
        return element.categoryId == newValue;
      });
    }
  }

  @Watch("searchText")
  onSearchTextChange(newValue: string) {
    if (!newValue) {
      this.filteredBlogs = this.blogs;
      return;
    }
    this.filteredBlogs = this.blogs.filter((b) => {
      return b.title.toLowerCase().includes(this.searchText.toLowerCase());
    });
  }

  formattedCreatedAtDate(isoString: string): string {
    var date = new Date(isoString);
    return date.toLocaleDateString("de");
  }

  isDescriptionLong(text: string): boolean {
    return text.length > this.maxNumberOfCharacters;
  }

  readMore(blog: BlogResponse): void {
    let category = this.categories.find((e) => e.id == blog.categoryId);
    if (category) {
      this.setActiveCategory(category);
    }
    this.setActiveBlog(blog);

    this.$router.push({
      name: "blogDetails",
      params: { id: blog.id },
    });
  }

  mounted(): void {
    Promise.all([this.fetchBlogs(), this.fetchBlogCategories()]).then(() => {
      this.loading = false;
      this.filteredBlogs = this.blogs;
    });
  }

  popToBlogs(): void {
    this.$router.go(-1);
  }
}
</script>
