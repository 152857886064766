<template>
  <LoadingIndicatorComponent v-if="loading" />

  <div v-else-if="error"></div>

  <!-- Content -->
  <v-container class="mt-7" fluid v-else>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <h1 class="text-center text-uppercase font-weight-light display-2">
          {{ $t("blogs") }}
        </h1>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" lg="9" md="10">
        <!-- Blog Cards -->
        <v-row justify="center">
          <v-col
            v-for="(blog, index) in filteredBlogs"
            :key="blog.id"
            cols="12"
            sm="6"
            md="4"
          >
            <v-card>
              <div class="flex-center">
                <!-- Image -->
                <v-img height="250" :src="blog.imageURL" />
              </div>
              <v-container>
                <!-- Title -->
                <div
                  class="text-uppercase text-h5 my-2 text-center text--disabled"
                  v-html="blog.title"
                />
                <v-divider class="my-2" />

                <!-- Tags -->
                <div class="text-center">
                  <ChipComponent class="text-uppercase" :items="blog.tags" />
                </div>
                <v-divider class="my-2" />

                <!-- Description -->
                <p
                  class="black--text mt-4 mb-4 text-start"
                  v-html="getSlicedDescriptionIfNeeded(blog.description)"
                />

                <v-divider class="my-2" />

                <!-- Created at -->
                <div class="text-overline black--text mt-2 text--disabled">
                  {{ $t("blogCreatedAt") }}
                  {{ formattedCreatedAtDate(blog.createdAt) }}
                </div>
                <v-divider />

                <!-- Actions -->
                <v-card-actions>
                  <v-row class="d-flex justify-end my-2">
                    <OutlinedButtonComponent
                      :title="$t('readMore')"
                      :action="() => readMore(blog)"
                      class="mt-3 primary--text"
                    />
                  </v-row>
                </v-card-actions>
              </v-container>
            </v-card>
            <!-- Add divider if not last blog -->
            <v-divider v-if="index !== filteredBlogs.length - 1" class="mx-4" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-btn
        rounded
        class="font-weight-bold mt-4"
        @click="viewAll"
        color="primary"
      >
        {{ $t("blogViewAll") }}
      </v-btn>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { BlogCategory, BlogResponse } from "@/components/blog/shared/types";
import ChipComponent from "@/components/common/ChipComponent.vue";
import LoadingIndicatorComponent from "@/components/common/LoadingIndicatorComponent.vue";
import OutlinedButtonComponent from "@/components/common/OutlinedButtonComponent.vue";

@Component({
  components: {
    ChipComponent,
    LoadingIndicatorComponent,
    OutlinedButtonComponent,
  },
})
export default class BlogGridComponent extends Vue {
  loading = true;
  maxNumberOfCharacters = 100;
  selectedCategoryId = null;
  filteredBlogs: BlogResponse[] = [];

  @Getter("blog/blogs")
  readonly blogs!: BlogResponse[];
  @Getter("blog/categories")
  readonly categories!: BlogCategory[];
  @Getter("blog/error")
  readonly error!: boolean;

  @Mutation("blogDetails/setBlog")
  setActiveBlog!: (blog: BlogResponse) => void;
  @Mutation("blogDetails/setCategory")
  setActiveCategory!: (category: BlogCategory) => void;

  @Action("blog/fetchBlogs")
  fetchBlogs!: () => Promise<void>;
  @Action("blog/fetchBlogCategories")
  fetchBlogCategories!: () => Promise<void>;

  formattedCreatedAtDate(isoString: string): string {
    var date = new Date(isoString);
    return date.toLocaleDateString("de");
  }

  isDescriptionLong(text: string): boolean {
    return text.length > this.maxNumberOfCharacters;
  }

  getSlicedDescriptionIfNeeded(text: string): string {
    return this.isDescriptionLong(text)
      ? text.substring(0, this.maxNumberOfCharacters) + "..."
      : text;
  }

  readMore(blog: BlogResponse): void {
    let category = this.categories.find((e) => e.id == blog.categoryId);
    if (category) {
      this.setActiveCategory(category);
    }
    this.setActiveBlog(blog);

    this.$router.push({
      name: "blogDetails",
      params: { id: blog.id },
    });
  }

  viewAll(): void {
    this.$router.push({ name: "blog" });
  }

  // TODO: Fetch blogs on Home and pass to component
  mounted(): void {
    Promise.all([this.fetchBlogs(), this.fetchBlogCategories()]).then(() => {
      this.loading = false;
      this.filteredBlogs = this.blogs.slice(-3);
    });
  }
}
</script>
