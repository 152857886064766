<template>
  <div>
    <v-container>
      <v-row justify="center" align="center" class="py-2">
        <v-col cols="8" lg="6" md="6" sm="4">
          <router-link to="/">
            <v-img contain height="50" :src="require('@/assets/logo.png')" />
          </router-link>
        </v-col>

        <v-col cols="8" lg="6" md="6" sm="4">
          <div class="d-flex align-center flex-column font-weight-light">
            <a :href="'tel:' + $t('phoneNumber1')">
              <span>{{ $t("phonePrefix") + " " + $t("phoneNumber1") }}</span>
            </a>
            <a :href="'tel:' + $t('phoneNumber2')">
              <span>{{ $t("phonePrefix") + " " + $t("phoneNumber2") }}</span>
            </a>
            <a :href="'mailto:' + $t('email')">
              <span>{{ $t("emailPrefix") + " " + $t("email") }}</span>
            </a>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-parallax :src="require('@/assets/header_blog.avif')" height="300">
      <div class="parallax-overlay" />
      <v-row align="center" justify="start">
        <v-col
          class="text-h4 font-italic overlay-text text-wrap text-center"
          cols="10"
          lg="10"
          md="10"
          sm="10"
        >
          {{ $t("blogHeaderTitle") }}
        </v-col>
      </v-row>
    </v-parallax>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
@Component
export default class BlogHeaderComponent extends Vue {}
</script>

<style scoped>
.parallax-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.overlay-text {
  z-index: 2;
}
</style>
