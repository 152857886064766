import { initializeApp } from 'firebase/app';
import {
    collection, doc, DocumentData, DocumentSnapshot, getDoc, getDocs, getFirestore,
    QueryDocumentSnapshot
} from 'firebase/firestore/lite';

import { BlogCategory, BlogResponse } from '@/components/blog/shared/types';

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const CACHE_PREFIX = "firebase_cache_";

const fetchData = async <T>(
  collectionPath: string,
  mapFunction: (doc: QueryDocumentSnapshot<DocumentData>) => T
): Promise<Array<T>> => {
  try {
    const cacheKey = `${CACHE_PREFIX}${collectionPath}`;
    const cachedData = localStorage.getItem(cacheKey);
    const expirationTime = localStorage.getItem(`${cacheKey}_expiration`);

    if (
      cachedData &&
      expirationTime &&
      Date.now() < parseInt(expirationTime, 10)
    ) {
      const parsedData: Array<T> = JSON.parse(cachedData);
      return parsedData;
    }

    const dataCollection = collection(db, collectionPath);
    const dataSnapshot = await getDocs(dataCollection);

    const data: Array<T> = dataSnapshot.docs.map((doc) => mapFunction(doc));

    localStorage.setItem(cacheKey, JSON.stringify(data));

    const newExpirationTime = Date.now() + 1 * 24 * 60 * 60 * 1000;

    localStorage.setItem(
      `${cacheKey}_expiration`,
      newExpirationTime.toString()
    );

    return data;
  } catch (error: unknown) {
    throw new Error(`Error fetching data: ${(error as Error).message}`);
  }
};

const fetchById = async <T>(
  collectionPath: string,
  id: string,
  mapFunction: (doc: QueryDocumentSnapshot<DocumentData>) => T
): Promise<T | null> => {
  try {
    const cacheKey = `${CACHE_PREFIX}${collectionPath}_${id}`;
    const cachedData = localStorage.getItem(cacheKey);
    const expirationTime = localStorage.getItem(`${cacheKey}_expiration`);

    if (
      cachedData &&
      expirationTime &&
      Date.now() < parseInt(expirationTime, 10)
    ) {
      const parsedData: T = JSON.parse(cachedData);
      return parsedData;
    }

    const blogRef = doc(db, collectionPath, id);
    const blogSnapshot = await getDoc(blogRef);

    if (!blogSnapshot.exists()) {
      throw new Error("Blog not found");
    }

    const blogData = mapFunction(blogSnapshot);

    localStorage.setItem(cacheKey, JSON.stringify(blogData));

    const newExpirationTime = Date.now() + 1 * 24 * 60 * 60 * 1000;

    localStorage.setItem(
      `${cacheKey}_expiration`,
      newExpirationTime.toString()
    );

    return blogData;
  } catch (error: unknown) {
    throw new Error(`Error fetching data: ${(error as Error).message}`);
  }
};

const getBlogs = async (): Promise<Array<BlogResponse>> => {
  return fetchData<BlogResponse>("blogs", (doc) => {
    const docData = doc.data();
    return {
      id: doc.id,
      categoryId: docData.categoryId,
      createdAt: docData.createdAt,
      description: docData.description,
      imageURL: docData.imageURL,
      tags: docData.tags,
      title: docData.title,
    };
  });
};

const getBlogCategories = async (): Promise<Array<BlogCategory>> => {
  return fetchData<BlogCategory>("blogCategories", (doc) => {
    const docData = doc.data();
    return {
      id: doc.id,
      name: docData.name,
    };
  });
};

const getBlogById = async (blogId: string): Promise<BlogResponse | null> => {
  return fetchById<BlogResponse>(
    "blogs",
    blogId,
    (doc: DocumentSnapshot<DocumentData>) => {
      const docData = doc.data();
      return {
        id: doc.id,
        categoryId: docData?.categoryId,
        createdAt: docData?.createdAt,
        description: docData?.description,
        imageURL: docData?.imageURL,
        tags: docData?.tags,
        title: docData?.title,
      };
    }
  );
};

const getCategoryById = async (
  categoryId: string
): Promise<BlogCategory | null> => {
  return fetchById<BlogCategory>(
    "blogCategories",
    categoryId,
    (doc: DocumentSnapshot<DocumentData>) => {
      const docData = doc.data();
      return {
        id: doc.id,
        name: docData?.name,
      };
    }
  );
};

export { getBlogs, getBlogCategories, getBlogById, getCategoryById };
