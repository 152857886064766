<template>
  <div>
    <v-parallax dark src="@/assets/header.svg" height="750">
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row>
            <v-col cols="12" md="6" xl="8">
              <v-container class="d-flex flex-column align-start">
                <v-img contain src="@/assets/logo.png" max-height="70" />
                <h1 class="font-weight-light black--text">
                  {{ $t("mainHomeTitle") }}
                </h1>
                <v-btn
                  rounded
                  outlined
                  large
                  dark
                  @click="$vuetify.goTo('#features')"
                  class="mt-5 black--text"
                >
                  {{ $t("readMore") }}
                  <v-icon class="ml-2">mdi-arrow-down</v-icon>
                </v-btn>
              </v-container>
            </v-col>
            <v-col cols="12" md="6" xl="4" class="hidden-sm-and-down" />
          </v-row>
        </v-col>
      </v-row>
      <div class="svg-border-waves text-white">
        <v-img src="@/assets/img/borderWaves.svg" />
      </div>
    </v-parallax>
    <div class="toggle">
      <v-row>
        <CountryFlag country="gb" class="mt-3"></CountryFlag>
        <v-switch light color="accent" class="ml-2" v-model="switchVal" />
        <CountryFlag country="ba" class="mt-3 mr-2"></CountryFlag>
      </v-row>
    </div>
  </div>
</template>
<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import CountryFlag from "vue-country-flag";
@Component({ components: { CountryFlag } })
export default class HeaderComponent extends Vue {
  langKey = "aboutLang";
  switchVal = true;

  @Watch("switchVal") onSwitchChange(switchVal: boolean): void {
    if (switchVal) {
      this.$i18n.locale = "ba";
      window.localStorage.setItem(this.langKey, "ba");
    } else {
      this.$i18n.locale = "en";
      window.localStorage.setItem(this.langKey, "en");
    }
  }

  mounted(): void {
    let lang = "ba";
    if (window.localStorage !== undefined) {
      if (!window.localStorage.getItem(this.langKey))
        window.localStorage.setItem(this.langKey, lang);
    }
    if (
      window.localStorage.getItem(this.langKey) &&
      window.localStorage.getItem(this.langKey) === "en"
    ) {
      this.$i18n.locale = "en";
      this.switchVal = false;
    } else if (
      window.localStorage.getItem(this.langKey) &&
      window.localStorage.getItem(this.langKey) === "ba"
    ) {
      this.$i18n.locale = "ba";
      this.switchVal = true;
    }
  }
}
</script>

<style scoped>
.toggle {
  position: absolute;
  top: 12px;
  right: 12px;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}

.svg-border-waves img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: -2px;
  z-index: -1;
}
</style>
