import { GetterTree } from 'vuex';

import { BlogCategory, BlogResponse } from '@/components/blog/shared/types';
import { RootState } from '@/store/RootState';

import { BlogState } from './types';

export const getters: GetterTree<BlogState, RootState> = {
  blogs(state: BlogState): BlogResponse[] {
    return state.blogs;
  },

  categories(state: BlogState): BlogCategory[] {
    return state.categories;
  },

  error(state: BlogState): boolean {
    return state.error;
  },
};
