const options = {
  customProperties: true,
};

const dark = false;

const themes = {
  dark: {
    // TODO: define dark theme if needed
  },
  light: {
    background: "#FFFFFF",
    backgroundDark: "#005049",
    backgroundSoft: "#F5F5F5",
    brand: "#533745",
    primary: "#00947A",
    secondary: "#F3F3F3",
    error: "#FF6347",
    success: "#32CD32",
    accent: "#F7B500",
  },
};

// GENERATED COLOR PALLETE AT -> https://mycolor.space/?hex=%238ABD4A&sub=1
// #8ABD4A
// #44AB66
// #00947A
// #007B7E
// #0F6172
// #2F4858

export { options, dark, themes };
