import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "ba",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "ba",
  messages: {
    en: require("../src/locales/en.json"),
    ba: require("../src/locales/ba.json"),
  },
});
