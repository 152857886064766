import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('BlogHeaderComponent'),(_vm.loading)?_c('LoadingIndicatorComponent'):_c(VContainer,{staticClass:"background",attrs:{"max-width":"1000","fill-height":""}},[_c(VRow,{staticClass:"mt-4",attrs:{"justify":"center"}},[_c('p',{staticClass:"text-h4 font-weight-bold text-center"},[_vm._v(" "+_vm._s(_vm.blog?.title)+" ")])]),_c(VImg,{staticClass:"mt-4",attrs:{"contain":"","src":_vm.blog?.imageURL,"max-height":"500"}}),_c('p',{staticClass:"text-h6 mt-12",domProps:{"innerHTML":_vm._s(_vm.blog?.description)}}),(_vm.blog)?_c(VRow,{staticClass:"mt-12",attrs:{"justify":"start"}},[_c('BlogShareComponent',{attrs:{"pageUrl":_vm.getPageUrl()}})],1):_vm._e(),_c(VRow,{staticClass:"mt-4 mx-2",attrs:{"justify":"space-between"}},[(_vm.category)?_c('p',{staticClass:"text-h5 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("blogCategory") + _vm.category?.name)+" ")]):_vm._e(),(_vm.blog)?_c('p',{staticClass:"text-overline"},[_vm._v(" "+_vm._s(_vm.$t("blogCreatedAt") + " " + _vm.formattedCreatedAtDate(_vm.blog?.createdAt))+" ")]):_vm._e()]),(_vm.blog)?_c(VRow,[_c(VDivider,{staticClass:"secondary darken-4"})],1):_vm._e(),(_vm.blog)?_c(VRow,{staticClass:"mt-8 mb-8",attrs:{"justify":"space-between"}},[_c('TextButtonComponent',{attrs:{"title":_vm.$t('navigateBack'),"action":_vm.popToBlogs,"leftIcon":"mdi-arrow-left-drop-circle","buttonClass":"text-h6"}}),_c('TextButtonComponent',{attrs:{"title":_vm.$t('scrollToTop'),"action":_vm.scrollToTop,"leftIcon":"mdi-arrow-up-drop-circle","buttonClass":"text-h6"}})],1):_vm._e(),(!_vm.blog)?_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,{staticClass:"mb-2",attrs:{"justify":"center"}},[_c(VIcon,{attrs:{"size":"80","color":"error"}},[_vm._v("mdi-alert")])],1),_c('p',{staticClass:"text-h5 font-weight-bold text-center"},[_vm._v(" "+_vm._s(_vm.$t("blogFetchingError"))+" ")])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }