<template>
  <section class="py-16" id="contact">
    <v-container fluid class="background">
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row justify="center">
            <v-col cols="12" sm="5">
              <h1 class="font-weight-light display-1">{{ $t("formTitle") }}</h1>
              <h3 class="font-weight-light mt-3">{{ $t("formParagraph") }}</h3>

              <h3 class="font-weight-light mt-3">
                {{ $t("phonePrefix") + " " + $t("phoneNumber1") }}
              </h3>
              <h3 class="font-weight-light">
                {{ $t("phonePrefix") + " " + $t("phoneNumber2") }}
              </h3>
              <h3 class="font-weight-light">
                {{ $t("emailPrefix") + " " + $t("email") }}
              </h3>
            </v-col>
            <v-col cols="12" sm="7">
              <v-form ref="form" v-model="valid" @submit.prevent="sendEmail">
                <v-text-field
                  v-model="name"
                  :rules="nameRules"
                  :label="$t('formName')"
                  required
                />

                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  :label="$t('formEmail')"
                  required
                />

                <v-textarea
                  v-model="message"
                  :rules="textAreaRules"
                  :label="$t('formMessage')"
                  required
                />
                <v-row justify="center" class="mt-2 mb-4">
                  <VueRecaptcha
                    :hidden="!valid"
                    :sitekey="siteKey"
                    :load-recaptcha-script="true"
                    @verify="captchaSuccess"
                    @error="captchaError"
                  />
                </v-row>
                <v-btn
                  :disabled="!valid || !captchaVerified"
                  color="primary"
                  rounded
                  block
                  class="mt-3"
                  type="submit"
                >
                  {{ $t("formSubmit") }}
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar v-model="snackbar" timeout="3000" :color="snackbarColor">
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">
          {{ $t("formSystemMessageClose") }}
        </v-btn>
      </template>
    </v-snackbar>
  </section>
</template>

<script lang="ts">
import { Component, Ref, Vue } from "vue-property-decorator";
import { VForm } from "@/components/common/store/types";
import { VueRecaptcha } from "vue-recaptcha";

import emailjs from "emailjs-com";
import OutlinedButtonComponent from "../common/OutlinedButtonComponent.vue";

@Component({ components: { OutlinedButtonComponent, VueRecaptcha } })
export default class FormComponent extends Vue {
  @Ref("form") readonly form!: VForm;

  valid = false;
  captchaVerified = false;
  name = "";
  email = "";
  message = "";
  snackbar = false;
  snackbarText = "";
  snackbarColor = "";

  get siteKey(): string {
    return process.env.VUE_APP_RECAPTCHA_SITE_KEY;
  }

  nameRules = [
    (v: string): string | boolean =>
      !!v || (this.$t("formFieldRequired") as string),
    (v: string): string | boolean =>
      v.length <= 50 || (this.$t("formNameInvalid") as string),
  ];
  textAreaRules = [
    (v: string): string | boolean =>
      !!v || (this.$t("formFieldRequired") as string),
    (v: string): string | boolean =>
      v.length <= 300 || (this.$t("formMessageInvalid") as string),
  ];
  emailRules = [
    (v: string): string | boolean =>
      !!v || (this.$t("formFieldRequired") as string),
    (v: string): string | boolean =>
      /.+@.+/.test(v) || (this.$t("formEmailInvalid") as string),
  ];

  sendEmail(): void {
    if (!this.valid) {
      this.snackbar = true;
      this.snackbarText = this.$t("formNotValid") as string;
      this.snackbarColor = "error";
      return;
    }

    emailjs
      .send(
        process.env.VUE_APP_EMAILJS_SERVICE_ID,
        process.env.VUE_APP_EMAILJS_TEMPLATE_ID,
        { name: this.name, email: this.email, message: this.message },
        process.env.VUE_APP_EMAILJS_USER_ID
      )
      .then(
        () => {
          this.discard();
          this.snackbar = true;
          this.snackbarText = this.$t("formSubmitSuccess") as string;
          this.snackbarColor = "success";
        },
        () => {
          this.discard();
          this.snackbar = true;
          this.snackbarText = this.$t("formSubmitFailure") as string;
          this.snackbarColor = "error";
        }
      );
  }

  discard(): void {
    this.name = "";
    this.email = "";
    this.message = "";
    this.form.resetValidation();
  }

  captchaSuccess() {
    this.captchaVerified = true;
  }

  captchaError() {
    this.captchaVerified = false;
  }
}
</script>
