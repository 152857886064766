import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VParallax } from 'vuetify/lib/components/VParallax';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(VParallax,{attrs:{"dark":"","src":require("@/assets/header.svg"),"height":"750"}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"10"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6","xl":"8"}},[_c(VContainer,{staticClass:"d-flex flex-column align-start"},[_c(VImg,{attrs:{"contain":"","src":require("@/assets/logo.png"),"max-height":"70"}}),_c('h1',{staticClass:"font-weight-light black--text"},[_vm._v(" "+_vm._s(_vm.$t("mainHomeTitle"))+" ")]),_c(VBtn,{staticClass:"mt-5 black--text",attrs:{"rounded":"","outlined":"","large":"","dark":""},on:{"click":function($event){return _vm.$vuetify.goTo('#features')}}},[_vm._v(" "+_vm._s(_vm.$t("readMore"))+" "),_c(VIcon,{staticClass:"ml-2"},[_vm._v("mdi-arrow-down")])],1)],1)],1),_c(VCol,{staticClass:"hidden-sm-and-down",attrs:{"cols":"12","md":"6","xl":"4"}})],1)],1)],1),_c('div',{staticClass:"svg-border-waves text-white"},[_c(VImg,{attrs:{"src":require("@/assets/img/borderWaves.svg")}})],1)],1),_c('div',{staticClass:"toggle"},[_c(VRow,[_c('CountryFlag',{staticClass:"mt-3",attrs:{"country":"gb"}}),_c(VSwitch,{staticClass:"ml-2",attrs:{"light":"","color":"accent"},model:{value:(_vm.switchVal),callback:function ($$v) {_vm.switchVal=$$v},expression:"switchVal"}}),_c('CountryFlag',{staticClass:"mt-3 mr-2",attrs:{"country":"ba"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }