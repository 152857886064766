<template>
  <div>
    <NavigationDrawerComponent :menu="menu" @item-click="handleItemClick" />

    <div>
      <HeaderComponent id="header" />
      <AboutComponent id="info" />
      <CounterComponent id="counter" />
      <BlogGridComponent id="blog" />
      <ClientsComponent id="clients" />
      <FormComponent id="contact" />
      <FooterComponent id="footer" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import NavigationDrawerComponent from "@/components/navigationDrawer/NavigationDrawerComponent.vue";
import HeaderComponent from "@/components/home/HeaderComponent.vue";
import AboutComponent from "@/components/home/AboutComponent.vue";
import FormComponent from "@/components/home/FormComponent.vue";
import BlogGridComponent from "@/components/blog/blogList/BlogGridComponent.vue";
import FooterComponent from "@/components/home/FooterComponent.vue";
import CounterComponent from "@/components/home/CounterComponent.vue";
import ClientsComponent from "@/components/home/ClientsComponent.vue";

@Component({
  components: {
    HeaderComponent,
    AboutComponent,
    FormComponent,
    BlogGridComponent,
    FooterComponent,
    NavigationDrawerComponent,
    CounterComponent,
    ClientsComponent,
  },
})
export default class HomeView extends Vue {
  menu = [
    { titleKey: "menuInfo", icon: "mdi mdi-alert-box", id: "info" },
    { titleKey: "menuBlog", icon: "mdi mdi-post", id: "blog" },
    { titleKey: "menuClients", icon: "mdi mdi-account", id: "clients" },
    { titleKey: "menuContact", icon: "mdi mdi-email", id: "contact" },
  ];

  handleItemClick(itemId: string): void {
    const element = document.getElementById(itemId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }
}
</script>
