<template>
  <v-btn
    text
    @click="handleClick"
    :color="fgColor"
    :type="type"
    class="font-weight-bold"
  >
    {{ title }}
  </v-btn>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class OutlinedButtonComponent extends Vue {
  @Prop({ type: String, required: true }) title!: string;
  @Prop({ type: Function }) action!: () => void;

  @Prop({ type: String, default: "" }) fgColor!: string;
  @Prop({ type: String, default: "" }) type!: string;

  buttonClass!: string;

  handleClick(): void {
    this.action();
  }
}
</script>
