<template>
  <v-btn text @click="handleClick" :color="textColor" :class="buttonClass">
    <v-icon v-if="leftIcon" class="mr-2">{{ leftIcon }}</v-icon>
    {{ title }}
  </v-btn>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class TextButtonComponent extends Vue {
  @Prop({ type: String, required: true }) title!: string;
  @Prop({ type: Function, required: true }) action!: () => void;

  @Prop({ type: String, default: "" }) leftIcon!: string;
  @Prop({ type: String, default: "backgroundDark" }) textColor!: string;
  @Prop({ type: String, default: "" }) buttonClass!: string;

  handleClick(): void {
    this.action();
  }
}
</script>
