import { Module } from 'vuex';

import { RootState } from '@/store/RootState';

import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { BlogState } from './types';

export const blog: Module<BlogState, RootState> = {
  namespaced: true,
  state: {
    error: false,
    categories: [],
    blogs: [],
  },
  getters: getters,
  mutations: mutations,
  actions: actions,
};
