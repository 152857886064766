<template>
  <div class="wrapper background">
    <v-container class="page-section" id="features">
      <v-row>
        <v-col cols="12" class="text-center">
          <h1 class="font-weight-light display-2 text-uppercase pb-8">
            {{ $t("servicesTitle") }}
          </h1>
        </v-col>
        <v-col class="px-6" sm="4" cols="12">
          <div class="feature-list">
            <img src="@/assets/icons/min1.svg" height="100" />
            <h5 class="text-h5 font-weight-bold">{{ $t("serviceCard1") }}</h5>
            <p class="text-body-1"></p>
          </div>
        </v-col>
        <v-col class="px-6" sm="4" cols="12">
          <div class="feature-list">
            <img src="@/assets/icons/min2.svg" height="100" />
            <h5 class="text-h5 font-weight-bold">{{ $t("serviceCard2") }}</h5>
            <p class="text-body-1"></p>
          </div>
        </v-col>
        <v-col class="px-6" sm="4" cols="12">
          <div class="feature-list">
            <img src="@/assets/icons/min3.svg" height="100" />
            <h5 class="text-h5 font-weight-bold">{{ $t("serviceCard3") }}</h5>
            <p class="text-body-1"></p>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-row align="center" justify="center" class="my-4">
      <v-col cols="10">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row align="center" justify="center">
            <v-col cols="12" md="7">
              <h1 class="font-weight-light display-1 mb-3">
                {{ $t("servicesHeadline") }}
              </h1>
              <v-row>
                <v-col cols="12" class="d-flex align-center">
                  <v-icon color="primary" class="icon">
                    mdi-circle-medium
                  </v-icon>

                  <p class="mt-3 ml-2">
                    {{ $t("serviceCard1Text") }}
                  </p>
                </v-col>
                <v-col cols="12" class="d-flex align-center">
                  <v-icon color="primary" class="icon">
                    mdi-circle-medium
                  </v-icon>

                  <p class="mt-3 ml-2">
                    {{ $t("serviceCard2Text") }}
                  </p>
                </v-col>
                <v-col cols="12" class="d-flex align-center">
                  <v-icon color="primary" class="icon">
                    mdi-circle-medium
                  </v-icon>

                  <p class="mt-3 ml-2">{{ $t("serviceCard3Text") }}</p>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="10" md="5" sm="10" lg="4">
              <v-img
                src="@/assets/img/illustration1.svg"
                class="d-block ml-auto mr-auto"
                height="auto"
                max-width="350"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import OutlinedButtonComponent from "../common/OutlinedButtonComponent.vue";

@Component({ components: { OutlinedButtonComponent } })
export default class AboutComponent extends Vue {
  features = [
    {
      title: this.$t("serviceCard1"),
      text: this.$t("serviceCard1Text"),
    },
    {
      title: this.$t("serviceCard2"),
      text: this.$t("serviceCard2Text"),
    },
    {
      title: this.$t("serviceCard3"),
      text: this.$t("serviceCard3Text"),
    },
  ];
}
</script>

<style scoped>
.wrapper {
  width: 100%;
}

.container {
  position: relative;
}

.page-section {
  margin-bottom: var(--spacing20); /* Use CSS variable syntax */
}

.feature-wrap {
  position: relative;
}

.feature-list {
  text-align: center;
}

.feature-list h5 {
  margin: var(--spacing3) 0; /* Use CSS variable syntax */
}

.feature-list .icon {
  color: var(--palette-primary-main); /* Use CSS variable syntax */
  font-size: 100px;
}

.feature-more {
  position: relative;
}

.feature-more .v-container .v-row {
  margin-bottom: var(--spacing20); /* Use CSS variable syntax */
}
</style>
