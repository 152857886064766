<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "App",
  components: {},
})
export default class App extends Vue {}
</script>

<style>
.v-application {
  background-color: var(--v-background-base) !important;
}
</style>
