import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.loading)?_c('LoadingIndicatorComponent'):_c('div',[_c('BlogHeaderComponent'),_c(VContainer,[_c(VContainer,{staticClass:"mb-8 px-4 py-0",attrs:{"fluid":""}},[_c(VRow,{staticClass:"mt-8",attrs:{"cols":"12","md":"6","lg":"6"}},[_c(VBtn,{staticClass:"mt-1 mr-1",attrs:{"icon":"","color":"primary darken-1"},on:{"click":_vm.popToBlogs}},[_c(VIcon,{attrs:{"size":"32"}},[_vm._v("mdi-arrow-left-drop-circle")])],1),_c(VTextField,{attrs:{"label":_vm.$t('blogSearch'),"outlined":"","dense":"","color":"primary darken-1","append-icon":"mdi-magnify","hide-details":""},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c(VRow,{staticClass:"mt-4",attrs:{"justify":"start"}},[_c(VChipGroup,{attrs:{"column":""},model:{value:(_vm.selectedCategoryId),callback:function ($$v) {_vm.selectedCategoryId=$$v},expression:"selectedCategoryId"}},_vm._l((_vm.categories),function(category){return _c(VChip,{key:category.id,attrs:{"value":category.id,"color":"primary darken-1 secondary--text","filter":""}},[_vm._v(" "+_vm._s(category.name)+" ")])}),1)],1)],1),_c(VList,{staticClass:"px-2 background"},_vm._l((_vm.filteredBlogs),function(blog){return _c(VListItem,{key:blog.id},[_c(VListItemContent,{attrs:{"data-aos":"fade","data-aos-delay":"150","data-aos-duration":"300"}},[_c(VRow,{attrs:{"justify":"space-around"}},[_c(VCol,{attrs:{"cols":"12","lg":"8","md":"12"}},[_c('h4',{staticClass:"text-h4",domProps:{"innerHTML":_vm._s(blog.title)}}),_c('p',{staticClass:"text-subtitle-1 pt-4",domProps:{"innerHTML":_vm._s(
                  blog.description.substring(0, _vm.maxNumberOfCharacters) + '...'
                )}}),_c(VBtn,{staticClass:"mt-4",attrs:{"color":"primary darken-1 secondary--text"},on:{"click":function($event){return _vm.readMore(blog)}}},[_vm._v(" "+_vm._s(_vm.$t("readMore"))+" ")])],1),_c(VCol,{attrs:{"cols":"12","lg":"4","md":"12"}},[_c(VImg,{attrs:{"cover":"","height":"300","aspect-ratio":"4/3","src":blog.imageURL}})],1)],1),_c(VDivider,{staticClass:"mt-3"})],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }