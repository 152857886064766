<template>
  <v-container fluid class="primary mt-4" v-observe-visibility="startCounting">
    <v-row align="center" justify="center" class="root">
      <v-col cols="12" sm="12" md="4">
        <v-card flat class="text-center secondary--text primary pa-4">
          <div class="d-flex justify-center">
            <img src="@/assets/icons/medal.svg" class="mr-2" height="70" />
            <div class="text">
              <h3 class="text-h3">{{ satisfactionCounter }}%</h3>
              <div class="h3">{{ $t("counterText1") }}</div>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-card flat class="text-center secondary--text primary pa-4">
          <div class="d-flex justify-center">
            <img src="@/assets/icons/client.svg" class="mr-2" height="70" />
            <div class="text">
              <h3 class="text-h3">{{ regularClientsCounter }}+</h3>
              <div class="subtitle-2">{{ $t("counterText2") }}</div>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="4">
        <v-card flat class="text-center secondary--text primary pa-4">
          <div class="d-flex justify-center">
            <img
              src="@/assets/icons/project.svg"
              class="mr-2"
              height="70"
              color="secondary"
            />
            <div class="text">
              <h3 class="text-h3">{{ completedProjectsCounter }}+</h3>
              <div>{{ $t("counterText3") }}</div>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { ObserveVisibility } from "vue-observe-visibility";

@Component({
  directives: {
    ObserveVisibility,
  },
})
export default class CounterComponent extends Vue {
  isCounted = false;
  satisfactionCounter = 0;
  regularClientsCounter = 0;
  completedProjectsCounter = 0;

  animateCounters(property: string, targetValue: number) {
    const duration = 2000;
    const increment = Math.ceil(targetValue / (duration / 50));
    const currentValue = this[property as keyof CounterComponent];
    let counter = currentValue;

    const animationInterval = setInterval(() => {
      counter += increment;
      if (counter >= targetValue) {
        clearInterval(animationInterval);
        counter = targetValue;
      }
      this.$set(this, property, counter);
    }, 50);
  }

  startCounting(isVisible: boolean) {
    if (isVisible && !this.isCounted) {
      this.animateCounters("satisfactionCounter", 100);
      this.animateCounters("regularClientsCounter", 200);
      this.animateCounters("completedProjectsCounter", 3000);
      this.isCounted = true;
    }
  }
}
</script>
